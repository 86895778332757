import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Booking = () => {
  const [adultNumber, setAdultNumber] = useState(1);
  const [childNumber, setChildNumber] = useState(0);

  const calculateTickets = () => {
    return adultNumber + childNumber;
  };
  const [ticketType, setTicketType] = useState("");
  const [price, setPrice] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [message, setMessage] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [value, setValue] = useState(0);
  const [max, setMax] = useState(false);
  const [loading, setLoading] = useState(false);

  // Get the ticketType value from localStorage
  const storedTicketType = localStorage.getItem("ticketType");
  // Effect to run once on component mount
  useEffect(() => {
    // If it exists, update the ticketType state
    if (storedTicketType) {
      setTicketType(storedTicketType);
      if (storedTicketType == "Regular Entrance") {
        setPrice(2500);
        localStorage.setItem("ticketPrice", price);
      } else if (storedTicketType == "VIP Entrance") {
        setPrice(7500);
        localStorage.setItem("ticketPrice", price);
      } else {
        setPrice(100000);
        localStorage.setItem("ticketPrice", price);
      }
    }
  }, [price]);

  // Update local storage and state when the selection changes
  const handleChange = (event) => {
    const selectedType = event.target.value;
    localStorage.setItem("ticketType", selectedType);
    setTicketType(selectedType);
    if (selectedType == "Regular Entrance") {
      setPrice(2500);
      localStorage.setItem("ticketPrice", price);
    } else if (selectedType == "VIP Entrance") {
      setPrice(7500);
      localStorage.setItem("ticketPrice", price);
    } else {
      setPrice(100000);
      localStorage.setItem("ticketPrice", price);
    }
  };
  useEffect(
    (storedTicketType) => {
      if (localStorage.getItem("ticketType") == "VVIP Seated Lounge") {
        setMax(true);
      } else {
        setMax(false);
      }
    },
    [storedTicketType]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      firstName,
      lastName,
      ticketType,
      passportNumber,
      email,
      mobile,
      numberOfTickets: calculateTickets(),
      numOfAdult: adultNumber,
      numOfChild: childNumber,
      totalPrice: (adultNumber + childNumber / 2) * price,
      message,
    };
    setLoading(true);
    try {
      const response = await fetch(
        "https://eventapi.soho-sharm.com/api/BookingTicket/CreateOrder",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
        setLoading(false);
      }

      const data = await response.json();
      setApiResponse(data);
      localStorage.setItem("orderID", data.orderID);
      setLoading(false);
      localStorage.setItem(
        "orderConfirmationNumber",
        data.orderConfirmationNumber
      );
      window.location = data.kashierUrl;

      setFirstName("");
      setLastName("");
      setEmail("");
      setMobile("");
      setPassportNumber("");
      setAdultNumber(1);
      setChildNumber(0);
      setMessage("");
    } catch (error) {
      alert("Submission failed", error);
      setLoading(false);
    }
  };
  return (
    <>
      <section className="subscription-section subscription-1    custom-inner-bg position-relative mt-200 mb-100 col-lg-8 mx-auto">
        <div className="container  ">
          <div className="subscription-wrapper py-50 py-lg-70  px-5 pt-xxl-70 pb-xxl-30">
            <div className="row justify-content-between gy-40 gy-lg-0">
              <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                  <div className="row flex-wrap d-flex justify-content-between gx-lg-5">
                    <div className="subscription-form position-relative col-12 col-lg-6  mb-5">
                      <p>Select Ticket Type:</p>
                      <select
                        className="form-control fs-5"
                        value={ticketType}
                        onChange={(e) => {
                          setTicketType(e.target.value);
                          handleChange(e);
                        }}
                        required
                      >
                        <option value="0" hidden>
                          Select Type Of Ticket
                        </option>
                        <option value="Regular Entrance">
                          Regular Entrance
                        </option>
                        <option value="VIP Entrance">VIP Entrance</option>
                        {/* <option value="VVIP Seated Lounge">
                          VVIP Seated Lounge
                        </option> */}
                      </select>
                      <span className="subscription-form-arrow-bottom" />
                    </div>
                    <div className="subscription-form position-relative col-lg-6 mb-5">
                      <p>Tickets Number</p>
                      <div className="adult-mobile">
                        <button
                          type="button"
                          onClick={() =>
                            adultNumber > 1 && setAdultNumber(adultNumber - 1)
                          }
                        >
                          -
                        </button>
                        <input
                          required
                          type="number"
                          className="form-control fs-5"
                          placeholder="Adult Number"
                          value={adultNumber}
                          onChange={(e) =>
                            setAdultNumber(parseInt(e.target.value) || 1)
                          }
                          min={1}
                        />
                        <button
                          type="button"
                          onClick={() => setAdultNumber(adultNumber + 1)}
                        >
                          +
                        </button>
                      </div>
                      <input
                        required
                        type="number"
                        className="form-control fs-5 adult-desktop"
                        placeholder="Adult Number"
                        value={adultNumber}
                        onChange={(e) =>
                          setAdultNumber(parseInt(e.target.value))
                        }
                        min={1}
                      />
                    </div>

                    <div className="subscription-form position-relative col-12 col-lg-6 mb-5">
                      <p>Tickets And Price:</p>
                      <input
                        required
                        type="text"
                        className="form-control fs-5"
                        placeholder="Number Of Tickets"
                        value={`Number Of Tickets: ${calculateTickets()}    ||    Total Price: ${
                          adultNumber * price
                        } EGP`}
                        disabled
                      />
                    </div>

                    <div className="subscription-form position-relative col-12 col-lg-6 mb-5">
                      <p>First Name</p>
                      <input
                        required
                        type="text"
                        className="form-control fs-5"
                        placeholder="Enter your First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="subscription-form position-relative col-12 col-lg-6 mb-5">
                      <p>Last Name</p>
                      <input
                        required
                        type="text"
                        className="form-control fs-5"
                        placeholder="Enter your Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className="subscription-form position-relative col-lg-6 mb-5">
                      <p>Email</p>
                      <input
                        required
                        type="email"
                        className="form-control fs-5"
                        placeholder="Enter your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="subscription-form position-relative col-lg-6 mb-5">
                      <p>Mobile</p>
                      <input
                        required
                        type="text"
                        className="form-control fs-5"
                        placeholder="Enter your Mobile Number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                    <div className="subscription-form position-relative col-lg-6 mb-5">
                      <p>National ID / Passport Number</p>
                      <input
                        required
                        type="text"
                        className="form-control fs-5"
                        placeholder="Enter Number"
                        value={passportNumber}
                        onChange={(e) => setPassportNumber(e.target.value)}
                      />
                    </div>
                    <div className="subscription-form position-relative col-lg-12 mb-5">
                      <p>Message</p>
                      <textarea
                        rows="5"
                        className="form-control fs-5"
                        placeholder="Notes..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-gradient d-inline-flex align-items-center gap-2 w-auto mx-auto my-5"
                      aria-label="buttons"
                    >
                      {loading ? (
                        "Loading..."
                      ) : (
                        <>
                          <span className="buttons-logo">
                            <svg width="25" height="25">
                              <use xlinkHref="#buttons-logo"></use>
                            </svg>
                          </span>{" "}
                          "Book Now"
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Booking;
