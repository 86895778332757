import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./Home";
import Booking from "./Booking";
import ScrollToTop from "./ScrollToTop";
import Thanks from "./Thanks";
import PageTracker from "./components/PageTracker";
function App() {
  useEffect(() => {
    const currentUrl = new URL(window.location.href);

    if (!currentUrl.pathname.includes("/wael-jassar")) {
      window.location.href = `${currentUrl.origin}/wael-jassar${currentUrl.pathname}${currentUrl.search}`;
    }
  }, []);
  return (
    <>
      <Router>
        <PageTracker />
        <ScrollToTop />
        <Routes>
          <Route path="/wael-jassar" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="book" element={<Booking />} />
            <Route path="thank-you" element={<Thanks />} />{" "}
            <Route path="wael-jassar/thank-you" element={<Thanks />} />{" "}
            <Route
              path="*"
              element={
                <div
                  className="container m-auto my-5"
                  style={{ height: "50vh" }}
                >
                  <p className="m-auto d-flex align-items-center justify-content-center h-100  text-center">
                    Page Not Found!
                  </p>
                </div>
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
