import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
const Thanks = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const orderID = localStorage.getItem("orderID");
  useEffect(() => {
    // Function to fetch order details
    async function fetchOrderDetails() {
      try {
        const response = await fetch(
          `https://eventapi.soho-sharm.com/api/BookingTicket/GetOrderDetails?OrderId=${orderID}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }

    fetchOrderDetails();
  }, []);
  return (
    <>
      <section className="subscription-section subscription-1    custom-inner-bg position-relative mt-200 mb-100 col-lg-8 mx-auto">
        <div className="container  ">
          <div className="subscription-wrapper py-50 py-lg-70  px-5 pt-xxl-70 pb-xxl-30">
            <div className="row justify-content-between gy-40 gy-lg-0">
              <div className="col-lg-12">
                <div className="row flex-wrap d-flex justify-content-between gx-lg-5 mb-5">
                  <h2 className="text-center">
                    Thank You {orderDetails?.firstName}
                  </h2>
                </div>
                <Table striped>
                  <tbody>
                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Payment Status:
                      </td>
                      <td className="w-75">
                        {orderDetails?.orderStatus?.status}
                      </td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Order Confirmation Number:
                      </td>
                      <td className="w-75" style={{ verticalAlign: "middle" }}>
                        {orderDetails?.orderConfirmationNumber}
                      </td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Order Tickets:
                      </td>
                      <td className="w-75">
                        <ol>
                          {orderDetails?.orderTickets?.map((ticket, index) => (
                            <li key={index}>{ticket.ticketNumber} </li>
                          ))}
                        </ol>
                      </td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Currency:
                      </td>
                      <td className="w-75">{orderDetails?.pMcurrency}</td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold  bg-light text-dark">
                        Card:
                      </td>
                      <td className="w-75"> {orderDetails?.pMmaskedCard}</td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Payment Reference:
                      </td>
                      <td className="w-75">
                        {" "}
                        {orderDetails?.pMmerchantOrderId}
                      </td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold  bg-light text-dark">
                        Card Brand:
                      </td>
                      <td className="w-75">{orderDetails?.pMcardBrand}</td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Order Reference:
                      </td>
                      <td className="w-75">
                        {" "}
                        {orderDetails?.pMorderReference}
                      </td>
                    </tr>

                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Ticket Type:
                      </td>
                      <td className="w-75">{orderDetails?.ticketType}</td>
                    </tr>
                    <tr className="w-100">
                      <td className="w-25 fw-bold bg-light text-dark">
                        Order Date:
                      </td>
                      <td className="w-75">{orderDetails?.orderDate}</td>
                    </tr>
                    <tr className="w-100 ">
                      <td className="w-25 fw-bold  py-3 bg-light text-dark">
                        Total Price:
                      </td>
                      <td className="w-75  fw-bold py-3">
                        {orderDetails?.totalPrice}{" "}
                        <sup>{orderDetails?.pMcurrency}</sup>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thanks;
