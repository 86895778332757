import React, { useState, useEffect } from "react";

function CountdownTimer({ targetDate }) {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="countdown-section pb-40 pb-lg-60 pb-xl-80 pt-4 pt-lg-30 pt-xxl-40 position-relative">
      <div className="container">
        <div className="countdown countdown-gradient">
          <div className="row row-cols-2 row-cols-lg-3 row-cols-xl-4 justify-content-between align-items-center mx-auto">
            <div className="col">
              <div className="countdown-item">
                <span className="countdown-number primary-text-shadow">
                  {timeLeft.days || "00"}
                </span>
                <span className="countdown-label text-opacity">Days</span>
              </div>
            </div>
            <div className="col">
              <div className="countdown-item">
                <span className="countdown-number primary-text-shadow">
                  {timeLeft.hours || "00"}
                </span>
                <span className="countdown-label text-opacity">Hours</span>
              </div>
            </div>
            <div className="col">
              <div className="countdown-item">
                <span className="countdown-number primary-text-shadow">
                  {timeLeft.minutes || "00"}
                </span>
                <span className="countdown-label text-opacity">Minutes</span>
              </div>
            </div>
            <div className="col">
              <div className="countdown-item">
                <span className="countdown-number primary-text-shadow">
                  {timeLeft.seconds || "00"}
                </span>
                <span className="countdown-label text-opacity">Seconds</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimer;
