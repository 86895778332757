import React, { useState } from "react";
import logo from "./logo.svg";

import CountdownTimer from "./components/CountdownTimer";
import { useEffect } from "react";

import "swiper/css";
import Fancybox from "./components/fancybox-library/fancybox";
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Counter from "./components/Counter";
import { Link } from "react-router-dom";
import CountdownTimerTop from "./components/CountdownTimerTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const Home = ({ type, src, videoUrl, setCursorText }) => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 20000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    variableWidth: true,
    swipe: true,
    centerPadding: "0px",
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 997,
        settings: {
          slidesToShow: 34,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 10000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          cssEase: "linear",
          pauseOnHover: false,
          variableWidth: false,
          swipe: true,
          centerPadding: "0px",
          draggable: true,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 19000,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    arrows: false,
    variableWidth: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 997,
        settings: {
          slidesToShow: 34,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 10000,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 0,
          cssEase: "linear",
          pauseOnHover: false,
          variableWidth: false,
          swipe: true,
          centerPadding: "0px",
          draggable: true,
        },
      },
    ],
  };
  const settings3 = {
    dots: false,
    arrows: false,
    infinite: true,

    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,

    cssEase: "linear",
    pauseOnHover: false,

    draggable: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 997,
        settings: {
          slidesToShow: 34,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };
  const handleMouseEnter = (text) => setCursorText(text);
  const handleMouseLeave = () => setCursorText("");
  const brandImages = [
    "assets/images/hero-brand-1.png",
    "assets/images/hero-brand-2.png",
  ];

  const galleryItems = [
    {
      src: "assets/images/gallery-1.jpg",
      type: "video",
      href: "http://www.youtube.com/watch?v=0O2aH4XLbto",
    },
    {
      src: "assets/images/gallery-2.jpg",
      type: "image",
      href: "assets/images/gallery-2.jpg",
    },
    {
      src: "assets/images/gallery-3.jpg",
      type: "video",
      href: "http://www.youtube.com/watch?v=0O2aH4XLbto",
    },
    {
      src: "assets/images/gallery-4.jpg",
      type: "image",
      href: "assets/images/gallery-4.jpg",
    },
    {
      src: "assets/images/gallery-5.jpg",
      type: "video",
      href: "http://www.youtube.com/watch?v=0O2aH4XLbto",
    },
  ];

  const galleryItems2 = [
    {
      src: "assets/images/gallery-6.jpg",
      type: "image",
      href: "assets/images/gallery-6.jpg",
    },
    {
      src: "assets/images/gallery-7.jpg",
      type: "video",
      href: "http://www.youtube.com/watch?v=0O2aH4XLbto",
    },
    {
      src: "assets/images/gallery-8.jpg",
      type: "image",
      href: "assets/images/gallery-8.jpg",
    },
    {
      src: "assets/images/gallery-9.jpg",
      type: "video",
      href: "http://www.youtube.com/watch?v=0O2aH4XLbto",
    },
    {
      src: "assets/images/gallery-10.jpg",
      type: "image",
      href: "assets/images/gallery-10.jpg",
    },
    {
      src: "assets/images/gallery-11.jpg",
      type: "video",
      href: "http://www.youtube.com/watch?v=0O2aH4XLbto",
    },
  ];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="hero-section hero-8 position-relative max-width">
        <div className="hero-wrapper mx-auto position-relative parallax">
          <div className="hero-8-image">
            <img
              src="wael-jassar.png"
              className="img-fluid"
              alt="wael jassar "
            />
          </div>

          <div className="container">
            <div className="hero-8-inner">
              <h1 className="hero-heading-text text-white text-uppercase custom-poppins mb-3 mb-xxl-20">
                Wael Jassar
              </h1>
              <div className="col-lg-7 mt-lg-3 pt-lg-4">
                <h3 className="display-5 custom-jakarta fw-extra-bold mb-0 pb-0 fs-3  ">
                  <FontAwesomeIcon icon={faCalendarAlt} className="me-2 " />
                  <u>19 June 2024</u>
                </h3>
                <CountdownTimerTop targetDate="2024-06-19T22:00:00" />
              </div>
              {/*<div className="mb-40 mb-lg-0 hero-brand-images">
                <h3 className="fst-italic fw-semibold mb-3 text-white">
                  Powered by{" "}
                </h3>
                <div className="d-flex flex-md-wrap align-items-center gap-20 gap-md-5 gap-lg-50">
                  <Link to="#." aria-label="brand-image">
                    <img
                      src="assets/images/hero-brand-1.png"
                      className="img-fluid"
                      alt=" "
                      style={{
                        width: "140px",
                        backgroundColor: "#fff",
                        height: "60px",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    />
                  </Link>
                  <Link to="#." aria-label="brand-image">
                    <img
                      src="assets/images/hero-brand-2.png"
                      className="img-fluid"
                      alt=" "
                      style={{
                        width: "140px",
                        backgroundColor: "#fff",
                        height: "60px",
                        borderRadius: "5px",
                      }}
                    />
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-section about-1 pt-50 pt-lg-100 pt-xxl-150">
        <div className="container">
          <div className="row gy-50 gy-lg-0 gx-80 justify-content-lg-between align-items-lg-center">
            <div className="col-lg-12">
              <div className="about">
                <div className="section-title section-title-style-2 mb-2 mb-lg-10 mb-xxl-20 ">
                  <h3 className="sub-title display-3 fw-extra-bold primary-text-shadow custom-roboto">
                    Wael Jassar Live at SOHO Square
                  </h3>
                </div>
                <h1 className="display-5 custom-jakarta fw-extra-bold mb-4 mb-lg-20 mb-xxl-30">
                  Unforgettable Melodies at SOHO Square
                </h1>
                <p>
                  Indulge in an evening of enchantment as WAEL JASSAR takes the
                  stage, filling the air with his soulful voice and captivating
                  performances. This special event promises to be a highlight of
                  the Eid El Adha celebrations at SOHO Square, offering a blend
                  of musical excellence and festive spirit.
                </p>
                <p>
                  Join us for a magical night of music, dance, and celebration
                  in the heart of Sharm El Sheikh.
                </p>
              </div>
            </div>

            {/*<div className="col-lg-6">
              <div className="about-content-wrapper position-relative">
                <div className="about-image-1 position-relative">
                  <div className="about-image-wrapper">
                    <img
                      src="assets/images/home-1/about-image-1.jpg"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <Fancybox>
                    <div className="video-popup video-popup-center position-absolute">
                      <div className="circle-wrapper">
                        <div className="circle-bg"></div>

                        <a
                          href="http://www.youtube.com/watch?v=0O2aH4XLbto"
                          className="inner-circle video-icon video-popup-link video-popup-link hover-area"
                          data-fancybox="gallery-2"
                        >
                          <span>
                            <svg width="30" height="30">
                              <use xlinkHref="#video-icon" />
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </Fancybox>
                </div>
                <div className="about-image-2"></div>
                <div className="ellipse-image-1">
                  <img
                    src="assets/images/home-1/ellipse-1.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/*<CountdownTimer targetDate="2024-04-11T22:00:00" /> */}
      <section className="container pt-20 pb-30">
        <hr />
      </section>
      <section
        id="pricing"
        className="pricing-section pricing-1  pb-30 pb-lg-60"
      >
        <div className="container">
          <div className="row gy-4 gy-lg-0 align-items-lg-end justify-content-lg-between mb-30 mb-lg-70">
            <div className="col-lg-4">
              <div className="section-title section-title-style-2">
                <span className="fs-3 straight-line-wrapper fw-semibold position-relative">
                  <span className="straight-line"></span>
                  Ticket
                </span>
                <h3 className="sub-title display-3 fw-extra-bold primary-text-shadow custom-roboto">
                  Reservations
                </h3>
              </div>
            </div>
            <div className="col-lg-6" style={{ display: "none" }}>
              <div className="highlights-text">
                <ul>
                  <li>Children under 6 years old gain free admission.</li>
                  <li>
                    Children aged 6 to 12 qualify for a 50% discount on the
                    ticket price.
                  </li>
                  <li>Full ticket price is required starting age 12 </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row gx-30 gy-50 mx-auto justify-content-center">
            <div className="col-md-6 col-xl-4">
              <div className="pricing-wrapper bg-ticket position-relative">
                <div className="pricing-top-content">
                  {/*<div className="pricing-icon mb-10">
                    <img src="standing.svg" alt="standing icon" width="50" />
                  </div> */}
                  <h3 className="fw-semibold pricing-title">
                    Regular Entrance
                  </h3>
                  <h3 className="mb-40">
                    <span className="display-2 fw-extra-bold primary-text-shadow me-10">
                      <small>
                        2500 <sup>EGP</sup>
                      </small>{" "}
                    </span>
                  </h3>
                  <ul className="pricing-list list-unstyled d-flex flex-column gap-10">
                    <li
                      className="mb-0"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <span className="check-icon w-auto">
                        <svg width="16" height="16">
                          <use href="#check-icon"></use>
                        </svg>
                      </span>
                      <span>
                        {" "}
                        <p> Including 1 drink (soft or hard).</p>
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="pricing-separator">
                  <svg
                    viewBox="0 0 420 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3632_1203)">
                      <g clipPath="url(#clip1_3632_1203)">
                        <path
                          d="M30 -418H390C406.016 -418 419 -405.016 419 -389V8.63272C419 16.9435 414.514 24.6071 407.267 28.6754C390.261 38.222 390.261 62.702 407.267 72.2486C414.514 76.3169 419 83.9804 419 92.2912V190C419 206.016 406.016 219 390 219H30C13.9838 219 1 206.016 1 190V92.9604C1 84.3293 5.89026 76.4435 13.6218 72.607C31.9323 63.5211 31.9323 37.4029 13.6218 28.317C5.89026 24.4804 1 16.5946 1 7.9635V-389C1 -405.016 13.9837 -418 30 -418Z"
                          strokeWidth="2"
                        ></path>
                        <line
                          x1="28"
                          y1="49"
                          x2="394"
                          y2="49"
                          strokeWidth="2"
                          strokeDasharray="10 10"
                        ></line>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_3632_1203">
                        <rect width="420" height="100" fill="white"></rect>
                      </clipPath>
                      <clipPath id="clip1_3632_1203">
                        <rect
                          width="420"
                          height="616"
                          fill="white"
                          transform="translate(0 -419)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="pricing-bottom-content position-relative">
                  <Link
                    to="book"
                    className="btn btn-outline-gradient"
                    aria-label="buttons"
                    // onClick={handleShow}
                    onClick={() =>
                      localStorage.setItem("ticketType", "Regular Entrance")
                    }
                  >
                    Buy Now{" "}
                  </Link>
                  {/* <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                      Tickets Are Sold Out!
                    </Modal.Body>
                  </Modal> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="pricing-wrapper bg-ticket position-relative vip-ticket">
                <div className="pricing-top-content">
                  {/*<div className="pricing-icon mb-10">
                    <img
                      src="VIP-Seated.svg"
                      alt="VIP Ticket icon"
                      width="50"
                    />
                  </div> */}
                  <h3 className="fw-semibold pricing-title">VIP Entrance </h3>
                  <h3 className="mb-40">
                    <span className="display-2 fw-extra-bold primary-text-shadow me-10 ">
                      <small>
                        {" "}
                        7500 <sup>EGP</sup>
                      </small>
                    </span>
                  </h3>
                  <ul className="pricing-list list-unstyled d-flex flex-column gap-10">
                    <li
                      className="mb-0"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <span className="check-icon w-auto">
                        <svg width="16" height="16">
                          <use href="#check-icon"></use>
                        </svg>
                      </span>
                      <p>Offering a premium seated experience.</p>
                    </li>
                    <li
                      className="mb-0"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <span className="check-icon w-auto">
                        <svg width="16" height="16">
                          <use href="#check-icon"></use>
                        </svg>
                      </span>
                      <p>Including 2 drinks (soft or hard).</p>
                    </li>
                  </ul>
                </div>

                <div className="pricing-separator">
                  <svg
                    viewBox="0 0 420 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3632_1203)">
                      <g clipPath="url(#clip1_3632_1203)">
                        <path
                          d="M30 -418H390C406.016 -418 419 -405.016 419 -389V8.63272C419 16.9435 414.514 24.6071 407.267 28.6754C390.261 38.222 390.261 62.702 407.267 72.2486C414.514 76.3169 419 83.9804 419 92.2912V190C419 206.016 406.016 219 390 219H30C13.9838 219 1 206.016 1 190V92.9604C1 84.3293 5.89026 76.4435 13.6218 72.607C31.9323 63.5211 31.9323 37.4029 13.6218 28.317C5.89026 24.4804 1 16.5946 1 7.9635V-389C1 -405.016 13.9837 -418 30 -418Z"
                          strokeWidth="2"
                        ></path>
                        <line
                          x1="28"
                          y1="49"
                          x2="394"
                          y2="49"
                          strokeWidth="2"
                          strokeDasharray="10 10"
                        ></line>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_3632_1203">
                        <rect width="420" height="100" fill="white"></rect>
                      </clipPath>
                      <clipPath id="clip1_3632_1203">
                        <rect
                          width="420"
                          height="616"
                          fill="white"
                          transform="translate(0 -419)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="pricing-bottom-content position-relative">
                  <Link
                    to="book"
                    className="btn btn-outline-gradient"
                    aria-label="buttons"
                    // onClick={handleShow}
                    onClick={() =>
                      localStorage.setItem("ticketType", "VIP Entrance")
                    }
                  >
                    Buy Now{" "}
                  </Link>
                </div>
              </div>
            </div>

            {/*<div className="col-md-6 col-xl-4">
              <div className="pricing-wrapper bg-ticket position-relative">
                <div className="pricing-top-content">
                  <div className="pricing-icon vvip mb-10">
                    <img
                      src="VVIP-Seated-Lounge.svg"
                      alt="vvip icon"
                      width="50"
                    />
                  </div>
                  <h3 className="fw-semibold pricing-title">
                    VVIP Seated Lounge{" "}
                  </h3>
                  <h3 className="mb-40">
                    <span className="display-2 fw-extra-bold primary-text-shadow me-10 ">
                      <small> 100,000 EGP</small>
                    </span>
                  </h3>
                  <ul className="pricing-list list-unstyled d-flex flex-column gap-10">
                    <li
                      className="mb-0"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <span className="check-icon w-auto">
                        <svg width="16" height="16">
                          <use href="#check-icon"></use>
                        </svg>
                      </span>
                      <p>Accommodating 4 guests.</p>
                    </li>
                    <li
                      className="mb-0"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <span className="check-icon w-auto">
                        <svg width="16" height="16">
                          <use href="#check-icon"></use>
                        </svg>
                      </span>{" "}
                      <p>
                        {" "}
                        Selection of 2 imported alcohol bottles and soft drinks
                        included.
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="pricing-separator">
                  <svg
                    viewBox="0 0 420 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3632_1203)">
                      <g clipPath="url(#clip1_3632_1203)">
                        <path
                          d="M30 -418H390C406.016 -418 419 -405.016 419 -389V8.63272C419 16.9435 414.514 24.6071 407.267 28.6754C390.261 38.222 390.261 62.702 407.267 72.2486C414.514 76.3169 419 83.9804 419 92.2912V190C419 206.016 406.016 219 390 219H30C13.9838 219 1 206.016 1 190V92.9604C1 84.3293 5.89026 76.4435 13.6218 72.607C31.9323 63.5211 31.9323 37.4029 13.6218 28.317C5.89026 24.4804 1 16.5946 1 7.9635V-389C1 -405.016 13.9837 -418 30 -418Z"
                          strokeWidth="2"
                        ></path>
                        <line
                          x1="28"
                          y1="49"
                          x2="394"
                          y2="49"
                          strokeWidth="2"
                          strokeDasharray="10 10"
                        ></line>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_3632_1203">
                        <rect width="420" height="100" fill="white"></rect>
                      </clipPath>
                      <clipPath id="clip1_3632_1203">
                        <rect
                          width="420"
                          height="616"
                          fill="white"
                          transform="translate(0 -419)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="pricing-bottom-content position-relative">
                  <Link
                    to="book"
                    className="btn btn-outline-gradient"
                    aria-label="buttons"
                    onClick={() =>
                      localStorage.setItem("ticketType", "VVIP Seated Lounge")
                    }
                  >
                    Buy Now{" "}
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* 
       <section
        id="sponsor"
        className="sponsor-section sponsor-1 bg-lg custom-inner-bg position-relative pt-15 pt-lg-40 pt-xxl-50 pb-15 pb-lg-40 pb-xxl-50 mb-60"
      >
        <div className="ellipse-image-1">
          <img src="assets/images/home-1/ellipse-1.png" alt="ellipse-1" />
        </div>
        <div className="container">
          <div className="row gy-4 gy-lg-0 align-items-lg-end justify-content-lg-between mb-30 mb-lg-70">
            <div className="col-lg-12">
              <div className="section-title section-title-style-2">
                <h1 className=" straight-line-wrapper fw-semibold position-relative">
                  <span className="straight-line"></span> Sponsored by{" "}
                </h1>
              </div>
          
            </div>
          </div>
      
          <div className="brand-logos position-relative mx-auto px-0   col-lg-6">
            <Slider {...settings3}>
              {brandImages.map((src, index) => (
                <div className="brand-image position-relative" key={index}>
                  <Link to="#.">
                    <img
                      src={src}
                      className="img-fluid mx-5"
                      alt="brand "
                      style={{
                        width: "250px",
                        backgroundColor: "#fff",
                        height: "105px",
                        borderRadius: "5px",
                      }}
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
           
        </div>
      </section>
      <div className="gallery-section gallery-1 pt-70 pt-lg-100 pt-xxl-120 pb-70 pb-lg-100 pb-xxl-120">
        <Slider {...settings}>
          {galleryItems.map((item, index) => (
            <div className="gallery-image px-3">
              <Fancybox key={index}>
                <a
                  href={item.href}
                  className={`${item.type}-popup-link hover-area`}
                  data-fancybox="gallery"
                  data-cursor-text={item.type === "video" ? "Video" : "Image"}
                >
                  <img src={item.src} alt="Gallery Item" />
                </a>
              </Fancybox>
            </div>
          ))}
        </Slider>
        <Slider {...settings2}>
          {galleryItems2.map((item, index) => (
            <Fancybox key={index}>
              <div className="gallery-image px-3 mt-5">
                <a
                  href={item.href}
                  className={`${item.type}-popup-link hover-area`}
                  data-fancybox="gallery"
                  data-cursor-text={item.type === "video" ? "Video" : "Image"}
                >
                  <img src={item.src} alt="Gallery Item" />
                </a>
              </div>
            </Fancybox>
          ))}
        </Slider>
      </div>
      
      <section className="cta-section cta-1 pb-50 pb-lg-80">
        <div className="container">
        <div className="row gy-20 gy-lg-0 align-items-lg-center justify-content-lg-between">
          <div className="col-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="fs-180-style-2 fw-extra-bold text-secondary d-flex align-items-center gap-2 mb-0">
                
                <span className="odometer" data-count-to="30">
                  <Counter start={0} end={30} duration={3} />
                </span>
                <span className="d-flex flex-column cta-percent-off">
                  <span className="cta-percent fw-extra-bold">% </span>
                  <span className="cta-off fw-extra-bold">Off </span>
                </span>
              </h2>
              <div className="d-block d-lg-none">
                <div className="cta-icon">
                  <Link to="#ticket" aria-label="icons">
                    <span className="arrow-up-right-big arrow-secondary">
                      <svg width="205" height="205">
                        <use xlinkHref="#arrow-up-right-big"></use>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <h2 className="cta-text ms-xl-n60 display-3 fw-extra-bold text-opacity">
              Get Your Tickets Today!
            </h2>
          </div>
          <div className="col-lg-3">
            <div className="cta-icon d-none d-lg-block ms-xl-70 ms-xxl-100">
              <Link to="#ticket" aria-label="icons">
                <span className="arrow-up-right-big arrow-secondary">
                  <svg width="205" height="205">
                    <use xlinkHref="#arrow-up-right-big"></use>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    </>
  );
};

export default Home;
