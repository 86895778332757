import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import BackgroundAudio from "./BackgroundAudio";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
const Layout = () => {
  const [inHome, setInHome] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    if (currentUrl.pathname.includes("thank-you")) {
      setInHome(false);
    }
  }, []);
  return (
    <>
      <header
        style={{
          backgroundColor: !inHome && "#121212",
          marginTop: !inHome && "0",
        }}
        className="header-section header-2 ordered-list-header sticky-navbar hover-menu"
      >
        <div className="container-fluid">
          <nav className="navbar navbar-expand-xl">
            <div className="d-flex px-lg-5 w-100 justify-content-between align-items-center">
              <Link
                className="navbar-brand d-flex"
                to="/wael-jassar"
                aria-label="nav-brands"
                onClick={() => setInHome(true)}
              >
                <img
                  src="/logoblack.png"
                  className="logo-light pe-3"
                  alt="logo"
                />
                <img
                  src="/logoblack.png"
                  className="logo-dark pe-3"
                  alt="logo"
                />
                <img
                  src="/SavoyGroupLogo1.png"
                  className="logo-light ps-3 group"
                  style={{ borderLeft: "1px solid #fff" }}
                  alt="logo"
                />
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                // onClick={handleShow}
                onClick={() => localStorage.removeItem("ticketType")}
              >
                <Link
                  to="/wael-jassar/book"
                  className="btn btn-gradient d-inline-flex align-items-center gap-0 px-3 d-block d-lg-none"
                  aria-label="buttons"
                  onClick={() => setInHome(false)}
                >
                  <span className="buttons-logo">
                    {/* Ensure your SVG is correctly imported or accessible */}
                    {/* Example might require an actual SVG here or a different approach to embedding it */}
                    <svg width="25" height="25">
                      <use xlinkHref="#buttons-logo"></use>
                    </svg>
                  </span>{" "}
                  Buy Ticket
                </Link>
              </button>
              {/*<Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  Tickets Are Sold Out!
                </Modal.Body>
              </Modal> */}
              <div className="d-none d-lg-block">
                <div className="d-flex gap-40 align-items-center">
                  {/* Commented out section is omitted for brevity */}
                  <Link
                    to="book"
                    // onClick={handleShow}
                    onClick={() => {
                      localStorage.removeItem("ticketType");
                      setInHome(false);
                    }}
                    className="btn btn-gradient d-inline-flex align-items-center gap-2"
                    aria-label="buttons"
                  >
                    <span className="buttons-logo">
                      {/* Ensure your SVG is correctly imported or accessible */}
                      {/* Example might require an actuimport BackgroundAudio from './BackgroundAudio';
al SVG here or a different approach to embedding it */}
                      <svg width="25" height="25">
                        <use xlinkHref="#buttons-logo"></use>
                      </svg>
                    </span>{" "}
                    Buy Ticket
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <BackgroundAudio />

      <a
        href="#"
        className={`arrow ${isVisible ? "show" : ""}`}
        onClick={scrollToTop}
      >
        <button type="button" className="arrow-btn">
          <FontAwesomeIcon icon={faChevronUp} />
        </button>
      </a>

      <div className="main">
        <Outlet />
      </div>
      <section className="subscription-section subscription-1 bg-lg custom-inner-bg position-relative">
        <div className="ellipse-image-2">
          <img src="assets/images/home-1/ellipse-2.png" alt="ellipse-1" />
        </div>
        <div className="container">
          <div className="subscription-wrapper py-50 py-lg-70 py-xxl-100">
            <div className="row justify-content-between gy-40 gy-lg-0">
              <div className="col-lg-5">
                <span className="fs-3 straight-line-wrapper fw-semibold position-relative">
                  <span className="straight-line"></span>Contact Us{" "}
                </span>
                <div className="mt-10 mt-md-20 mt-lg-20 mt-xxl-50">
                  <Link
                    to="mailto:mail@soho-sharm.com"
                    className="fs-5 text-decoration-none d-block mb-4"
                  >
                    <FontAwesomeIcon className="me-3" icon={faEnvelope} />{" "}
                    mail@soho-sharm.com
                  </Link>
                  <Link
                    className="  fs-5 text-decoration-none d-block mb-4"
                    to="https://maps.app.goo.gl/n6TXx3FMS7W2Wty27"
                    target="_blank"
                  >
                    <FontAwesomeIcon className="me-3 " icon={faLocationDot} />{" "}
                    SOHO Square Sharm El Sheikh, Egypt
                  </Link>
                  <Link
                    to="tel:+201000109109"
                    className="fs-5 text-decoration-none d-block mb-5"
                  >
                    <FontAwesomeIcon className="me-3" icon={faPhone} />
                    (+20) 1000109109
                  </Link>{" "}
                </div>
                <hr className="w-50" />

                <ul className="list-unstyled contact-icons d-flex align-items-center gap-20 gap-lg-30 mt-4 mt-lg-30 mb-0">
                  <li>
                    <Link
                      to="https://www.facebook.com/SohoSquareSharm/"
                      target="_Blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="fs-2 color-main"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/SOHOSquareSharm/"
                      target="_Blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="fs-2 color-main"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.tiktok.com/@sohosquaresharm?_t=8krIpgMW8X7&_r=1"
                      target="_Blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faTiktok}
                        className="fs-3 color-main"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/channel/UC84qbAM29VB394aEg8SuSRA/featured"
                      target="_Blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faYoutube}
                        className="fs-1 color-main"
                      />
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-5">
                <h3 className="straight-line-wrapper fw-semibold position-relative mb-20">
                  {" "}
                  <span className="straight-line"></span>Location
                </h3>
                <div className="map-image parallax position-relative">
                  <span className="map-marker map-marker-style-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-geo-alt-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                    </svg>
                  </span>

                  <div className="map-popup-content">
                    <p>SOHO Square Sharm El Sheikh, Egyp </p>
                    <Link
                      to="https://maps.app.goo.gl/n6TXx3FMS7W2Wty27"
                      className="btn btn-gradient btn-sm d-flex align-items-center justify-content-center custom-roboto gap-10 btn-map-direction"
                      target="_Blank"
                      rel="noopener noreferrer"
                    >
                      Get Direction{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </section>
      <footer className="footer-section footer-1 py-lg-50 py-40 mt-auto">
        <div className="container">
          <div className="row gy-lg-0 gy-10 justify-content-between align-items-center text-center text-lg-start">
            <div className="col-lg-4 order-3 order-lg-1">
              <p className="custom-roboto mb-0 footer-nav">
                © 2024{" "}
                <Link
                  to="https://www.soho-sharm.com"
                  className="text-decoration-none"
                  target="_blank"
                >
                  Savoy Group.
                </Link>
                All Rights Reserved.
              </p>
            </div>
            <div className="col-lg-4 order-1 order-lg-2">
              <Link
                className="navbar-brand d-flex justify-content-center"
                to="/"
                aria-label="nav-brands"
              >
                <img
                  src="/logoblack.png"
                  className="logo-light pe-3"
                  alt="logo"
                />
                <img
                  src="/SavoyGroupLogo1.png"
                  className="logo-light ps-3"
                  alt="logo"
                  style={{ borderLeft: "1px solid #fff" }}
                />
              </Link>
            </div>
            <div className="col-lg-4 order-2 order-lg-3">
              <ul className="footer-nav list-unstyled d-flex justify-content-center justify-content-lg-end gap-30 mb-0">
                <li className="nav-item">
                  <span className="d-flex">
                    Powered By{" "}
                    <Link
                      to="https://www.titegypt.com/"
                      className="nav-link ms-2"
                      aria-label="nav-links"
                    >
                      T.I.T Solutions
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
