import React, { useState, useEffect } from "react";

function CountdownTimerTop({ targetDate }) {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="countdown-section pb-0 pb-lg-0 pb-xl-0 pt-0 pt-lg-0 pt-xxl-0 position-relative">
      <div className="container">
        <div className="countdown countdown-gradient">
          <div className="row row-cols-2 row-cols-lg-3 row-cols-xl-6 flex-column flex-lg-row justify-content-lg-between align-items-lg-center mx-auto">
            <div className="col     ">
              <div className="countdown-item top">
                <span className="countdown-number top primary-text-shadow">
                  {timeLeft.days || "00"}
                </span>
                <span className="countdown-label top text-opacity">Days</span>
              </div>
            </div>
            <div className="col    ">
              <div className="countdown-item top">
                <span className="countdown-number top primary-text-shadow">
                  {timeLeft.hours || "00"}
                </span>
                <span className="countdown-label top text-opacity">Hours</span>
              </div>
            </div>
            <div className="col   ">
              <div className="countdown-item top">
                <span className="countdown-number top primary-text-shadow">
                  {timeLeft.minutes || "00"}
                </span>
                <span className="countdown-label top text-opacity">
                  Minutes
                </span>
              </div>
            </div>
            <div className="col   ">
              <div className="countdown-item top">
                <span className="countdown-number top primary-text-shadow">
                  {timeLeft.seconds || "00"}
                </span>
                <span className="countdown-label top text-opacity">
                  Seconds
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountdownTimerTop;
